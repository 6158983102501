<template>
  <div>
    <div class="full-layout">
      <LayoutHeader />
      <div class="main-container">
        <main class="main">
          <slot />
        </main>
      </div>
      <LayoutFooter />
    </div>

    <div id="sidepanel-port"></div>

    <client-only>
      <FormsContainer />
    </client-only>
  </div>
</template>

<script setup>
import { useSettingsStore } from '~/stores/settingsStore'

const settingsStore = useSettingsStore()

const onMatchMedia = () => {
  if (window.matchMedia) {
    const mq = window.matchMedia('(min-width: 992px)')

    mq.addEventListener('change', ({ matches }) => {
      settingsStore.updateViewport(matches)
    })
  }

  settingsStore.updateViewport(window.innerWidth >= 992)
}

onMounted(() => onMatchMedia())
</script>

<style scoped lang="scss">
.full-layout {
  min-height: 100vh;
  background: #fff;

  // neeed to make it work on safari
  .main-container {
    min-height: calc(100vh - #{var(--site-header-height)});
    display: flex;

    & > main {
      height: inherit;
      width: 100%;
    }
  }
}
</style>
